import { Outlet, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import React, { useState } from 'react';

import Carousel from "../components/Carousel";

const programs = [
  {id: 5, name: 'program_5_name', date: 'program_5_date',  content: 'program_5_text', isPromotionHolder: false, photoAuthor: 'unknown', singlepicture: false, hasreadmore: false, pictures: ["/projects/senoina/1.jpg", "/projects/senoina/2.jpg", "/projects/senoina/3.jpg", "/projects/senoina/4.jpg", "/projects/senoina/5.jpg", "/projects/senoina/6.jpg", "/projects/senoina/7.jpg", "/projects/senoina/8.jpg"]},
  {id: 6, name: 'program_6_name', date: 'program_6_date',  content: 'program_6_text', isPromotionHolder: false, photoAuthor: 'unknown', singlepicture: false, hasreadmore: false, pictures: ["/projects/balkoncert/1.jpg", "/projects/balkoncert/2.jpg", "/projects/balkoncert/3.jpg", "/projects/balkoncert/4.jpg"]},
  {id: 1, name: 'program_1_name', date: 'program_1_date',  content: 'program_1_text', isPromotionHolder: false, photoAuthor: 'unknown', singlepicture: false, hasreadmore: false, pictures: ["/projects/wmn/1.png", "/projects/wmn/2.jpg", "/projects/wmn/3.jpg", "/projects/wmn/4.jpg", "/projects/wmn/5.jpg", "/projects/wmn/6.jpg", "/projects/wmn/7.jpg", "/projects/wmn/8.jpg", "/projects/wmn/9.jpg", "/projects/wmn/10.jpg", "/projects/wmn/11.jpg", "/projects/wmn/12.jpg", "/projects/wmn/13.jpg", "/projects/wmn/14.jpg", "/projects/wmn/15.jpg", "/projects/wmn/16.jpg", "/projects/wmn/17.jpg", "/projects/wmn/18.jpg"]},
  {id: 2, name: 'program_2_name', date: 'program_2_date',  content: 'program_2_text', isPromotionHolder: false, photoAuthor: 'Monika Rusak', singlepicture: false, hasreadmore: false, pictures: ["/projects/5posto/2.jpg", "/projects/5posto/3.jpg", "/projects/5posto/4.jpg", "/projects/5posto/5.jpg"]},
  {id: 3, name: 'program_3_name', date: 'program_3_date',  content: 'program_3_text', isPromotionHolder: false, photoAuthor: 'Monika Rusak', singlepicture: false, hasreadmore: false, pictures: ["/projects/GGTS/1.png", "/projects/GGTS/2.png", "/projects/GGTS/3.png"]},
  {id: 4, name: 'program_4_name', isPromotionHolder: true},

];

const promotions = [
  {id: 1, name: 'promotion_1_name', date: 'promotion_1_date',  content: 'promotion_1_text', photoAuthor: 'Monika Rusak', hasreadmore: false, pictures: ["/projects/Hana/1.jpg", "/projects/Hana/2.jpg", "/projects/Hana/3.jpg", "/projects/Hana/4.jpg", "/projects/Hana/5.jpg", "/projects/Hana/6.jpg"]},
  {id: 2, name: 'promotion_2_name', date: 'promotion_2_date',  content: 'promotion_2_text', photoAuthor: 'Monika Rusak', hasreadmore: false, pictures: ["/projects/Sanin/1.jpg", "/projects/Sanin/2.jpg", "/projects/Sanin/3.jpg"]},
];

const Programs = () => {
  const [programOpen, setProgramOpen] = useState(0);

  const handleProgram = (programId) => {
    if (programOpen > 0) 
      setProgramOpen(0); 
    else 
      setProgramOpen(programId);
  };

  return <div class="h-full mt-20 flex flex-col bg-black flex items-center">
          {programs.map(program =>
            <div class="w-full md:w-[80%] px-6 my-6" key={program.id}> 
              {/* 80% content holder */}

              <div class="w-full z-4 h-32 flex items-center">
                {/* wrapper headlinea programa */}
                          <div class="w-[70%] md:w-[80%] text-2xl md:text-6xl text-favela font-bold">
                            {/* wrapper naslova programa */}
                            <FormattedMessage id={program.name}/>
                          </div>

                          <Link onClick={() => handleProgram(program.id)} class="h-12 w-[30%] md:w-[20%] text-sm md:text-xl p-1 cursor-pointer font-thin text-favela border flex items-center justify-center border-favela hover:border-white hover:text-white">
                            <div class="text-white">
                              <FormattedMessage id={programOpen === program.id ? "read_less" : "read_more"}/>
                            </div>
                          </Link>
              </div>

              <div class={`w-full flex flex-col items-center program ${programOpen === program.id ? 'active' : ''}`}>
              {/* wrapper contenta programa - animation holder */}

                        <div class={`bg-darkgray w-[100%] md:w-[90%] ${program.isPromotionHolder ? '' : 'border border-favela'}`}>
                        {/* sub-wrapper contenta programa */}

                            {program.isPromotionHolder ? 
                              <div class="flex flex-col border-1 border-test">
                              {/* promotions wrapper */}
                                {promotions.map(promotion => 
                                    <div class="grid grid-cols-2 w-full" key={promotion.id}>
                                      <div class="col-span-2 lg:col-span-1 p-12">
                                      {/* visuals */}
                                        <div className="m-auto border-2 border-favela">
                                          {promotion.singlepicture ? <img src={promotion.pictures[0]} alt="home" class="w-full h-auto" /> : <Carousel slides={promotion.pictures} />}
                                        </div>
                                      </div>

                                      <div class="col-span-2 lg:col-span-1 p-12 text-xl text-white font-thin">
                                        {/* text */}
                                        <FormattedMessage id={promotion.content} values={{"b": chunks => <span class="font-bold">{chunks}</span>, "i": chunks => <span class="italic">{chunks}</span>, "span": chunks => <div class="w-full my-4">{chunks}</div>}}/>
                                      </div>
                                    </div>
                                )}
                              </div>
                            :
                              <div class="w-full grid grid-cols-2">
                              {/* standard programs wrapper */}
                                <div class="col-span-2 lg:col-span-1 md:p-12 flex items-center">
                                  {/* visuals */}
                                    <div className="m-auto border-2 border-favela">
                                      {program.singlepicture ? <img src={program.pictures[0]} alt="home" class="w-full h-auto" /> : <Carousel slides={program.pictures} />}
                                    </div>
                                </div>

                                <div class="col-span-2 lg:col-span-1 py-4 px-2 md:p-12 text-xl text-white font-thin">
                                  {/* text */}
                                  <FormattedMessage id={program.content} values={{"b": chunks => <span class="font-bold">{chunks}</span>, "i": chunks => <span class="italic">{chunks}</span>, "span": chunks => <div class="w-full my-4">{chunks}</div>}}/>
                                  {program.hasreadmore ? <p class="text-white pt-4"><FormattedMessage id="program_readmore"/><a target="_blank" class="text-favela hover:text-white" href={program.readmoreurl}>{program.readmoretext}</a></p> : <span></span>}
                                </div>
                              </div>

                            }

                        
                        </div>
              </div>
            </div>
          )}
        </div>;
  };
  
export default Programs;